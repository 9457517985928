@charset "utf-8";
blockquote,
body,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
li,
ol,
p,
pre,
td,
textarea,
th{margin: 0; padding: 0}
table{border-collapse: collapse; border-spacing: 0}
fieldset,
img{border: 0; outline: none; max-height: 100%; max-width: 100%}
address,
caption,
cite,
code,
dfn,
em,
th,
var{font-style: normal; font-weight: normal}
ol,
ul{list-style: none; margin: 0; padding: 0}
caption,
th{text-align: left}
h1,
h2,
h3,
h4,
h5,
h6{font-size: 100%; font-weight: normal}
q:after,
q:before{content: ''}
abbr,
acronym{border: 0}
body{font: 14px/1.5 '微软雅黑'}
input:focus,
select:focus{border: none; outline: none}
a{background: none; border: none; outline: none}
a:link{text-decoration: none;}
a:visited{text-decoration: none; outline: none}
a:hover{text-decoration: none}
a:active{text-decoration: none; outline: none}

.tl{text-align: left}
.tc{text-align: center}
.tr{text-align: right}
.fb{font-weight: bold}
.fn{font-weight: normal}
.tu{text-decoration: underline!important;}
.tn{text-decoration: none}
.t2{text-indent: 2em}
.te{white-space: nowrap; text-overflow: ellipsis; overflow: hidden}
.fl{float: left}
.fr{float: right}
.cl{clear: left}
.cr{clear: right}
.cb{clear: both}
.clearfix:after{content: '';display: block;clear: both;}
.pr{position: relative}
.pa{position: absolute}
.pf{position: fixed}
.vm{vertical-align: middle}
.bc{margin-left: auto; margin-right: auto}
.dib{display: inline-block}
.db{display: block!important;}
.dn{display: none!important;}
.vv{visibility: visible}
.vh{visibility: hidden}
.zoom{zoom: 1}
.w{width: 100%}
.wa{width: auto}
.h{height: 100%}
.ha{height: auto}

