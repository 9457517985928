@charset "utf-8";
@import "base.scss";
@import "sprite.scss";
//========================================变量========================================
$imgSrc:'../img/';
//========================================代码块========================================

//========================================MIXIN========================================
//box排版
@mixin composing($length:3, $gap:1%) {
  width: 100% / $length - $gap;
  margin-right: $gap + ($gap / ($length - 1));

  // margin-right: $gap \0;
  &:nth-child(#{$length}n) {
    margin-right: 0px;
  }
}

//========================================公共样式========================================
//--------------------雪碧图
@include sprites($spritesheet-sprites);

.icon {
  display: inline-block;
  vertical-align: middle;
}

.mainCa {
  color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2f323b;
  overflow: hidden;
  .logo {
    position: absolute;
    z-index: 2;
    top: 25px;
    left: 3.6%;
  }
  // 菜单
  .menu {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 32px;
    right: 3.6%;
    .single {
      color: #888888;
      line-height: 1;
      margin-left: 40px;
      font-size: 14px;
      cursor: pointer;
      border: none;
      outline: none;
      user-select: none;
      transition: all 0.3s ease;
      &.active,
      &:hover {
        color: #fff;
      }
    }
  }
  // 版权
  .copyright {
    position: absolute;
    z-index: 2;
    right: 3.6%;
    bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    cursor: auto;
    .single {
      position: relative;
      display: block;
      color: #888888;
      line-height: 1;
      font-size: 12px;
      cursor: pointer;
      &:first-child {
        margin-right: 25px;
      }
      &:hover{
        color: #fff;
        .details{
          display: block;
        }
      }
      .details{
        color: #000;
        display: none;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 1;
        text-align: center;
        transition: all 0.3s ease;
        background-color: #fff;
        border-radius: 4px;
        padding: 10px;
        white-space: nowrap;
        img{
          max-width: unset;
          max-height: unset;
        }
      }
    }
    .line {
      width: 1px;
      height: 12px;
      margin: 0 4px;
      background-color: #888888;
    }
  }
  //页面标题
  .pageTitle{
    position: absolute;
    top: 224px;
    left: 17.6%;
  }
}

#swiperMain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}