@charset "utf-8";
@import "common.scss";

// head 导航
.head{
  width: 1520px;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  .logo{
    .pic{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .navlist{
    .item{
      display: inline-block;
      font-size: 18px;
      color: #ffffff;
      margin: 0 48px;
      position: relative;
      cursor: pointer;
      transition: .3s;
      &.active,&:hover{
        &::before{
          content: '';
          width: 20px;
          height: 3px;
          background: #ffffff;
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        color: #fff;
      }
    }
  }
}
// banner 文字内容
.banner-info{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  line-height: 1;
  width: 1520px;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 100%;
  .title{
    font-size: 72px;
    color: #FFFFFF;
    font-weight: 600;
  }
  .desc{
    font-size: 36px;
    color: #ffffff;
    margin-top: 45px;
  }
  .bottom{
    margin-top: 98px;
    .btn-code{
      text-align: center;
      display: inline-block;
      font-size: 22px;
      color: #333333;
      width: 220px;
      line-height: 56px;
      border-radius: 2px;
      background: #ffffff;
      cursor: pointer;
      position: relative;
      .icon{
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/icon-code.png) no-repeat center;
        background-size: 100%;
        margin-right: 6px;
      }
      .info{
        position: absolute;
        top: 80px;
        background: #ffffff;
        width: 220px;
        height: 220px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: auto;
        display: none;
        box-shadow: 0 0 2px #dddddd;
        &::before{
          content: '';
          position: absolute;
          top: -30px;
          width: 0;
          height: 0;
          border-bottom: 16px solid #fff;
          border-right: 20px solid transparent;
          border-top: 20px solid transparent;
          border-left: 20px solid transparent;
        }
        .code{
          width: 210px;
          height: 210px;
          background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/download-code.png) no-repeat center;
          background-size: 100%;
        }
      }
      &:hover{
        .info{
          display: flex;
        }
      }
    }
  }
}
// banner
.banner{
  width: 100%;
  height: 100vh;
  background: url(https://aloss.11oi.com/html/officialWebsite/web/img/more/20-1.png) center no-repeat;
  position: relative;
  overflow: hidden;
  background-size: cover;
}
// 首页
.indexpage.banner{
  overflow: visible;
}
// product
.product{
  width: 100%;
  background: #ffffff;
  .tontainer{
    width: 1520px;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100%;
    height: 834px;
    margin: 0 auto;
    display: flex;
    .left{
      width: 50%;
      height: 100%;
      background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/product-left.png) no-repeat center;
      background-size: contain;
    }
    .right{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 40px;
      box-sizing: border-box;
      .info{
        .title{
          font-size: 48px;
          color: #323333;
          line-height: 1;
          margin-bottom: 50px;
        }
        .details{
          font-size: 18px;
          color: #656566;
          line-height: 2;
        }
        .bottom{
          margin-top: 100px;
        }
      }
    }
  }
}
// 资讯中心
.newscenter{
  width: 100%;
  padding-bottom: 180px;
  background: #F7F8FA;
  .tontainer{
    width: 1520px;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
    .title{
      font-size: 48px;
      color: #323333;
      line-height: 1;
      padding-top: 100px;
      .modification{
        font-weight: bold;
        margin-bottom: 30px;
        text-transform: uppercase;
      }
    }
    .tabs{
      width: 100%;
      border-bottom: 1px solid #E1E6E6;
      display: flex;
      justify-content: flex-end;
      .tab{
        padding-bottom: 50px;
        margin-left: 100px;
        font-size: 24px;
        color: #969999;
        transition: .3s;
        position: relative;
        cursor: pointer;
        &.active,&:hover{
          color: #D71C13;
          &::before{
            content: '';
            width: 40px;
            height: 5px;
            background: #D71C13;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .newslist{
      display: inline-block;
      margin-top: 100px;
      width: 100%;
      display: flex;
      .item{
        display: inline-block;
        width: 32%;
        margin-right: 2%;
        background: #ffffff;
        cursor: pointer;
        transition: .3s;
        &:last-child{
          margin-right: 0;
        }
        .preview{
          width: 100%;
          font-size: 0;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 0;
            bottom: 0;
            left: 0;
            background: rgba(215,28,19,0.3);
            transition: .1s;
          }
          .pic{
            width: 100%;
          }
        }
        .item-title{
          line-height: 1;
          font-size: 24px;
          color: #323333;
          margin-top: 28px;
          padding: 0 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time{
          line-height: 1;
          font-size: 14px;
          color: #646666;
          margin-top: 15px;
          padding: 0 20px;
          padding-bottom: 35px;
        }
        &:hover{
          background: #D71C13;
          .preview{
            &::before{
              height: 100%;
            }
          }
          .item-title,.time{
            color: #ffffff;
          }
        }
      }
    }
    .bottom{
      margin-top: 150px;
      text-align: center;
    }
  }
}

// 底部页脚
.foot{
  width: 100%;
  background: #ffffff;
  .tontainer{
    width: 1520px;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
    .top{
      margin-top: 120px;
      margin-bottom: 35px;
      display: flex;
      justify-content: space-between;
      .left{
        .navlist{
          .item{
            display: inline-block;
            margin-right: 60px;
            cursor: pointer;
            &:last-child{
              margin-right: 0;
            }
            &:hover{
              text-decoration: underline;
            }
            a{
              color: #323333;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
        .contact{
          margin-top: 46px;
          .item{
            display: inline-block;
            margin-right: 50px;
            cursor: pointer;
          }
          .weibo{
            width: 46px;
            height: 46px;
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/icon-weibo.png) no-repeat center;
          }
          .wechat{
            width: 46px;
            height: 46px;
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/icon-wechat.png) no-repeat center;
          }
        }
      }
      .right{
        display: flex;
        width: 300px;
        justify-content: space-between;
        .code{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .pic{
            width: 110px;
            height: 110px;
          }
          .desc{
            font-size: 16px;
            color: #323333;
            font-weight: bold;
          }
        }
      }
    }
    .bottom{
      border-top: 1px solid #E1E6E6;
      padding: 40px 0;
      box-sizing: border-box;
      p{
        line-height: 1;
        display: inline;
        margin-right: 20px;
        font-size: 14px;
        color: #969999;
      }
    }
  }
}

// 查看更多按钮
.btn-more{
  display: inline-block;
  width: 200px;
  height: 48px;
  line-height: 48px;
  background: #D71C13;
  border-radius: 2px;
  color: #ffffff;
  padding: 0 25px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  .icon{
    display: inline-block;
    width: 24px;
    height: 16px;
    background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/icon-more.png) no-repeat center;
    background-position-y: 44%;
    margin-left: 60px;
  }
  &:hover{
    opacity: 0.8;
  }
}

// 产品服务 页面
.banner-info{
  &.products{
    .title{
      font-size: 72px;
      font-weight: 400;
    }
    .desc{
      font-size: 50px;
      margin-top: 20px;
      text-transform: uppercase;
      letter-spacing: 4px;
    }
    .details{
      font-size: 30px;
      color: #ffffff;
    }
  }
}
.banner.products{
  background: url(https://aloss.11oi.com/html/officialWebsite/web/img/more/20-2.png) center no-repeat;
  background-size: cover;
}
.product{
  &.products{
    .tontainer{
      .left{
        background: url(https://aloss.11oi.com/html/officialWebsite/web/img/products/product-left.png) no-repeat center;
        background-size: contain;
      }
      .right{
        .title{
          margin-bottom: 30px;
        }
        .line{
          display: inline-block;
          width: 40px;
          height: 5px;
          background: #D71C13;
          margin-bottom: 25px;
        }
      }
    }
  }
}
.ydk{
  width: 100%;
  background:linear-gradient(130deg,rgba(249,76,82,1) 4%,rgba(255,83,89,1) 4%,rgba(234,77,71,1) 34%,rgba(229,56,48,1) 34%,rgba(215,28,19,1) 92%,rgba(227,52,43,1) 92%,rgba(254,69,74,1) 100%);
  .tontainer{
    width: 1520px;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100%;
    height: 834px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
  }
  .left{
    width: 50%;
    height: 100%;
    background: url(https://aloss.11oi.com/html/officialWebsite/web/img/products/ydk-left.png) no-repeat center;
    background-size: contain;
    background-position-y: 55%;
  }
  .right{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    box-sizing: border-box;
    .info{
      .title{
        font-size: 48px;
        color: #ffffff;
        line-height: 1;
        margin-bottom: 30px;
      }
      .line{
        display: inline-block;
        width: 40px;
        height: 5px;
        background: #ffffff;
        margin-bottom: 25px;
      }
      .details{
        font-size: 18px;
        color: #ffffff;
        line-height: 2;
      }
      .bottom{
        margin-top: 100px;
        .btn-more{
          background: #ffffff;
          color: #D71C13;
          .icon{
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/index/icon-more-red.png) no-repeat center;
          }
        }
      }
    }
  }
}

// 资讯中心 页面
.banner.news{
  height: 800px;
  background: url(https://aloss.11oi.com/html/officialWebsite/web/img/news/banner_news.png) center no-repeat;
  background-size: cover;
  .banner-info{
    .desc{
      margin-top: 30px;
    }
  }
}
.allnews{
  background: #ffffff;
  padding-top: 140px;
  .tontainer{
    padding: 0 20px;
    max-width: 100%;
    width: 1520px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      line-height: 1;
      font-size: 48px;
      color: #333333;
      .desc{
        font-weight: bold;
        margin-bottom: 30px;
      }
      .line{
        display: inline-block;
        width: 70px;
        height: 7px;
        background: #D81C14;
        margin-top: 60px;
      }
    }
    .right{
      .tabs{
        display: flex;
        .tab{
          width: 160px;
          line-height: 50px;
          text-align: center;
          font-size: 24px;
          color: #969999;
          margin-left: 30px;
          cursor: pointer;
          transition: .3s;
          &.active,&:hover{
            background: #D81C14;
            color: #fff;
          }
        }
      }
    }
  }
  // 列表
  .newslist{
    .list{
      padding: 80px 0;
      .list-card{
        width: 100%;
        transition: .3s;
        cursor: pointer;
        &:hover{
          background: #D81C14;
          color: #fff;
          .item{
            .right{
              .info{
                .title,.time,.desc{
                  color: #ffffff;
                }
                .line{
                  background: #ffffff;
                }
              }
            }
          }
        }
      }
      .item{
        width: 1520px;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 45px 20px;
        transition: .3s;
        cursor: pointer;
        .left{
          width: 40%;
          .preview{
            width: 100%;
            display: flex;
            align-items: center;
            .pic{
              max-width: 100%;
            }
          }
        }
        .right{
          margin-left: 10%;
          width: 50%;
          .info{
            line-height: 1;
            .title{
              font-size: 36px;
              color: #333434;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
            .desc{
              font-size: 18px;
              color: #646666;
              line-height: 2;
              margin-top: 15px;
              height: 80px;
              overflow:hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              word-break: break-all;
            }
            .line{
              display: inline-block;
              width: 86px;
              height: 1px;
              background: #646666;
              margin-top: 70px;
              margin-bottom: 45px;
            }
            .time{
              font-size: 18px;
              color: #646666;
            }
          }
        }
      }
    }
  }
}
.pagination{
  text-align: center;
}
#pagination.pagination ul li{
  border: none;
}
#pagination.pagination ul li:not(.totalPage){
  &:hover,&.active{
    transform: none;
    border: none;
    background: #D81C14;
    color: #ffffff;
  }
}

// 资讯中心 文章详情
.newspage{
  width: 100%;
  overflow: hidden;
  .head{
    position: relative;
    .navlist{
      .item{
        a{
          color: #333334;
        }
        &:hover,&.active{
          a{
            color: #D81C14;
          }
          &::before{
            background: #D81C14;
          }
        }
      }
    }
  }
  .tontainer{
    width: 1520px;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
  }
  .content{
    width: 100%;
    display: flex;
    // 推荐新闻
    .recommend{
      width: 320px;
      margin-top: 260px;
      margin-bottom: 40px;
      .title{
        font-size: 32px;
        color: #333333;
      }
      .line{
        display: inline-block;
        width: 40px;
        height: 5px;
        background: #D81C14;
        margin-top: 35px;
      }
      .list{
        width: 100%;
        .item{
          line-height: 2;
          border-bottom: 1px solid #E1E6E6;
          padding: 15px 0;
          margin-top: 15px;
          cursor: pointer;
          .time{
            font-size: 16px;
            color: #646666;
          }
          .desc{
            font-size: 18px;
            line-height: 1.5;
            color: #333434;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
          }
          &:hover{
            .desc{
              text-decoration: underline;
            }
          }
        }
      }
    }
    // 文章内容
    .newsdetails{
      flex: 1;
      margin-left: 10%;
      line-height: 1;
      margin-top: 150px;
      overflow: hidden;
      .title{
        font-size: 48px;
        color: #333333;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time{
        font-size: 18px;
      }
      .subtitle{
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        color: #333333;
        margin-top: 20px;
        .share-btn{
          display: inline-block;
          .icon{
            display: inline-block;
            font-size: 18px;
            color: #D81C14;
            padding-left: 20px;
            position: relative;
            cursor: pointer;
            &::before{
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              display: inline-block;
              width: 16px;
              height: 16px;
              background: url(https://aloss.11oi.com/html/officialWebsite/web/img/details/share-icon.png) no-repeat center;
            }
          }
          .social-share-info{
            display: none;
            position: absolute;
            flex-direction: column;
          }
          &:hover{
            .social-share-info{
              display: flex;
            }
          }
        }
      }
    }
  }
  // 模板-文章内容
  .details-content{
    margin-top: 50px;
    padding: 40px 0;
    border-top: 1px solid #C8CCCC;
    color: #656666;
    line-height: 1.5;
    font-size: 18px;
    img{
      max-width: 100%;
    }
  }
}

// 关于我们页面
.aboutus-page{
  background: #fff;
  width: 100%;
  overflow: hidden;
  .head{
    position: relative;
    .navlist{
      .item{
        a{
          color: #333334;
        }
        &:hover,&.active{
          a{
            color: #D81C14;
          }
          &::before{
            background: #D81C14;
          }
        }
      }
    }
  }
  // 关于我们
  .aboutus{
    width: 100%;
    margin-top: 120px;
    .aboutus-head{
      color: #333334;
      width: 100%;
      text-align: center;
      line-height: 1;
      .title{
        font-size: 60px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .line{
        display: inline-block;
        width: 70px;
        height: 7px;
        background: #D81C14;
        margin: 30px 0 25px;
      }
      .desc{
        font-size: 32px;
      }
    }
    .preview{
      margin-top: 50px;
      width: 100%;
      font-size: 0;
      .pic{
        width: 100%;
      }
    }
  }
  // 蚁淘生活是什么
  .ytsh{
    width: 100%;
    height: 710px;
    display: flex;
    .left{
      width: 45%;
      background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/mascot.png) no-repeat center;
    }
    .right{
      width: 55%;
      padding: 0 10% 0 0;
      display: flex;
      align-items: center;
      .info{
        line-height: 1;
        .title{
          font-size: 48px;
          color: #333333;
        }
        .line{
          display: inline-block;
          width: 70px;
          height: 7px;
          background: #D81C14;
          margin-top: 60px;
        }
        .desc{
          font-size: 18px;
          color: #656666;
          line-height: 2;
          margin-top: 40px;
        }
      }
    }
  }
  // 成长历程
  .history{
    background: #F8F9FA;
    padding: 160px 0;
    .title-head{
      width: 100%;
      text-align: center;
      .title{
        font-size: 48px;
        color: #333333;
        line-height: 1;
      }
      .line{
        display: inline-block;
        width: 70px;
        height: 7px;
        background: #D81C14;
        margin-top: 60px;
      }
    }
    .list{
      display: flex;
      justify-content: space-between;
      margin: 0 10%;
      margin-top: 140px;
      position: relative;
      &::before{
        content: '';
        width: 100%;
        height: 1px;
        background: #E1E6E6;
        position: absolute;
        left: 0;
        bottom: 85px;
      }
      .item{
        text-align: center;
        position: relative;
        .card{
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .time{
          font-size: 22px;
          color: #D81C14;
        }
        .line-vertical{
          display: inline-block;
          width: 1px;
          height: 20px;
          background: #D81C14;
          margin: 10px 0 30px;
        }
        .circular{
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #D81C14;
          border: 4px solid #F8F9FA;
          margin-top: -9px;
        }
        .desc{
          margin-top: 40px;
          font-size: 22px;
          color: #323333;
          display: block;
          height: 36px;
        }
      }
    }
  }
  // 价值观
  .values{
    padding: 180px 0;
    .title-head{
      width: 100%;
      text-align: center;
      .title{
        font-size: 48px;
        color: #333333;
        line-height: 1;
      }
      .line{
        display: inline-block;
        width: 70px;
        height: 7px;
        background: #D81C14;
        margin-top: 60px;
      }
    }
    .list{
      margin: 0 8%;
      margin-top: 200px;
      display: flex;
      justify-content: space-between;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon{
          width: 84px;
          height: 84px;
        }
        .title{
          font-size: 22px;
          color: #323333;
          margin-top: 50px;
        }
        .desc{
          font-size: 18px;
          color: #646666;
          margin-top: 20px;
          text-align: center;
        }
        &:nth-child(1){
          .icon{
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/icon-values-1.png) no-repeat center;
            background-size: contain;
          }
        }
        &:nth-child(2){
          .icon{
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/icon-values-2.png) no-repeat center;
            background-size: contain;
          }
        }
        &:nth-child(3){
          .icon{
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/icon-values-3.png) no-repeat center;
            background-size: contain;
          }
        }
        &:nth-child(4){
          .icon{
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/icon-values-4.png) no-repeat center;
            background-size: contain;
          }
        }
        &:nth-child(5){
          .icon{
            background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/icon-values-5.png) no-repeat center;
            background-size: contain;
          }
        }
      }
    }
  }
  // 愿景、使命
  .vision{
    width: 100%;
    display: flex;
    .item{
      width: 50%;
      height: 580px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      line-height: 1;
      &:nth-child(1){
        background: #D81C14;
      }
      &:nth-child(2){
        background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/mission.png) no-repeat center;
        background-size: cover;
      }
      .title{
        font-size: 60px;
      }
      .desc{
        font-size: 24px;
        margin-top: 40px;
      }
    }
  }
  // 公司环境
  .environment{
    padding: 180px 0 100px;
    .title-head{
      width: 100%;
      text-align: center;
      .title{
        font-size: 48px;
        color: #333333;
        line-height: 1;
      }
      .line{
        display: inline-block;
        width: 70px;
        height: 7px;
        background: #D81C14;
        margin-top: 60px;
      }
    }
    .list{
      width: 100%;
      .swiper-container{
        margin-top: 100px;
        .swiper-wrapper{
          display: flex;
          align-items: center;
        }
        .swiper-slide{
          padding: 5%;
          box-sizing: border-box;
          transition: .2s;
          &.swiper-slide-active{
            padding: 0;
          }
          img{
            width: 100%;
          }
        }
        .swiper-button-next{
          width: 70px;
          height: 60px;
          background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/rslider-right.png) no-repeat center #D81C14;
          cursor: pointer;
          transition: 0.3s;
          &:hover{
            opacity: 0.8;
          }
        }
        .swiper-button-prev{
          width: 70px;
          height: 60px;
          background: url(https://aloss.11oi.com/html/officialWebsite/web/img/aboutus/rslider-left.png) no-repeat center #D81C14;
          cursor: pointer;
          transition: 0.3s;
          &:hover{
            opacity: 0.8;
          }
        }
      }
    }
  }
}

// 联系我们页面
.contactuspage{
  .banner{
    height: 800px;
    background: url(https://aloss.11oi.com/html/officialWebsite/web/img/more/20-3.png) center no-repeat;
    background-size: cover;
    .title{
      font-weight: normal;
      font-size: 72px;
      letter-spacing: 4px;
    }
    .desc{
      text-transform: uppercase;
      font-size: 60px;
      margin-top: 38px;
      letter-spacing: 2px;
    }
    .details{
      font-size: 30px;
      color: #ffffff;
      margin-top: 90px;
    }
  }
  .content{
    padding: 150px 20px 120px;
    max-width: 100%;
    width: 1520px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #323333;
    .content-head{
      text-align: center;
      line-height: 1;
      .title{
        font-size: 48px;
      }
      .line{
        display: inline-block;
        width: 70px;
        height: 7px;
        background: #d71c13;
        margin-top: 40px;
      }
    }
    .contact-info{
      padding-top: 50px;
      .item{
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        .item-content{
          font-size: 18px;
          display: flex;
          &:nth-child(1){
            width: 45%;
          }
          &:nth-child(2){
            width: 40%;
          }
          &:nth-child(3){
            width: 15%;
          }
          .left{
            .icon{
              display: inline-block;
              width: 30px;
              height: 30px;
              &.zhaoshang{
                background: url(https://aloss.11oi.com/html/officialWebsite/web/img/contactus/icon-zhaoshang.png) no-repeat center;
              }
              &.rengong{
                background: url(https://aloss.11oi.com/html/officialWebsite/web/img/contactus/icon-rengong.png) no-repeat center;
              }
              &.zhaopin{
                background: url(https://aloss.11oi.com/html/officialWebsite/web/img/contactus/icon-zhaopin.png) no-repeat center;
              }
              &.company{
                background: url(https://aloss.11oi.com/html/officialWebsite/web/img/contactus/icon-company.png) no-repeat center;
              }
              &.address{
                background: url(https://aloss.11oi.com/html/officialWebsite/web/img/contactus/icon-address.png) no-repeat center;
              }
            }
          }
          .right{
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .title{
              line-height: 30px;
              margin-bottom: 25px;
            }
            a{
              color: #d71c13;
              text-decoration: underline;
            }
            >span{
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}