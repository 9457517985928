// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-close-name: 'icon-close';
$icon-close-x: 0px;
$icon-close-y: 41px;
$icon-close-offset-x: 0px;
$icon-close-offset-y: -41px;
$icon-close-width: 66px;
$icon-close-height: 66px;
$icon-close-total-width: 200px;
$icon-close-total-height: 107px;
$icon-close-image: '../img/sprite.png';
$icon-close: (0px, 41px, 0px, -41px, 66px, 66px, 200px, 107px, '../img/sprite.png', 'icon-close', );
$icon-logo-name: 'icon-logo';
$icon-logo-x: 0px;
$icon-logo-y: 0px;
$icon-logo-offset-x: 0px;
$icon-logo-offset-y: 0px;
$icon-logo-width: 200px;
$icon-logo-height: 31px;
$icon-logo-total-width: 200px;
$icon-logo-total-height: 107px;
$icon-logo-image: '../img/sprite.png';
$icon-logo: (0px, 0px, 0px, 0px, 200px, 31px, 200px, 107px, '../img/sprite.png', 'icon-logo', );
$icon-triangle-name: 'icon-triangle';
$icon-triangle-x: 76px;
$icon-triangle-y: 41px;
$icon-triangle-offset-x: -76px;
$icon-triangle-offset-y: -41px;
$icon-triangle-width: 9px;
$icon-triangle-height: 5px;
$icon-triangle-total-width: 200px;
$icon-triangle-total-height: 107px;
$icon-triangle-image: '../img/sprite.png';
$icon-triangle: (76px, 41px, -76px, -41px, 9px, 5px, 200px, 107px, '../img/sprite.png', 'icon-triangle', );
$spritesheet-width: 200px;
$spritesheet-height: 107px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($icon-close, $icon-logo, $icon-triangle, );
$spritesheet: (200px, 107px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
